// src/App.js

import { useEffect, useState, useRef } from 'react';
import { BrowserProvider, parseEther, formatEther, Contract } from 'ethers';

const MCSASHA_ADDRESS = "0x6DFE63380149E04f4DD9BD7E8d892eEc28878556";
const STAKING_ADDRESS = "0xF65a5AEf0076359dfEFaA3ab795eeABa85E783F5";

import MCSASHA_ABI from './MCSASHA_ABI.json';
import STAKING_ABI from './STAKING_ABI.json';
import './App.css';

function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);

  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [balance, setBalance] = useState("0");
  const [staked, setStaked] = useState("0");
  const [pendingRewards, setPendingRewards] = useState("0");
  const [allowance, setAllowance] = useState(0n); // BigInt

  // Stan dla muzyki
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const TOTAL_MCSASHA = 1000000000n * (10n ** 18n);
  const MAX_UINT256 = 2n ** 256n - 1n; // Definicja MAX_UINT256 jako BigInt

  useEffect(() => {
    if (typeof window !== 'undefined' && window.ethereum) {
      const _provider = new BrowserProvider(window.ethereum);
      setProvider(_provider);
    }
  }, []);

  const connectWallet = async () => {
    if (!provider) return;
    try {
      const accounts = await provider.send("eth_requestAccounts", []);
      setAccount(accounts[0]);
      const _signer = await provider.getSigner();
      setSigner(_signer);
      await fetchAllData(accounts[0], _signer);
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };

  const fetchAllData = async (userAddress, _signer) => {
    await fetchBalances(userAddress, _signer);
    await calculatePendingRewards(userAddress, _signer);
  };

  const fetchBalances = async (userAddress, _signer) => {
    if (!_signer) return;
    try {
      const mcSasha = new Contract(MCSASHA_ADDRESS, MCSASHA_ABI, _signer);
      const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, _signer);

      const bal = await mcSasha.balanceOf(userAddress);
      setBalance(formatEther(bal));

      const stakerInfo = await staking.stakers(userAddress);
      const stakedAmount = stakerInfo.amount;
      setStaked(formatEther(stakedAmount));

      const currentAllowance = await mcSasha.allowance(userAddress, STAKING_ADDRESS);
      setAllowance(BigInt(currentAllowance.toString())); // Konwersja na BigInt
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const calculatePendingRewards = async (userAddress, _signer) => {
    if (!_signer) return;
    try {
      const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, _signer);

      const stakerInfo = await staking.stakers(userAddress);
      const amount = BigInt(stakerInfo.amount.toString());
      const rewardBlock = BigInt(stakerInfo.rewardBlock.toString());

      const totalStaked = BigInt((await staking.totalStaked()).toString());
      const currentBlock = BigInt(await provider.getBlockNumber());
      const blocksPassed = currentBlock > rewardBlock ? currentBlock - rewardBlock : 0n;

      if (amount === 0n || totalStaked === 0n || blocksPassed === 0n) {
        setPendingRewards("0");
        return;
      }

      const stakedFraction = (totalStaked * 1000000000000000000n) / TOTAL_MCSASHA;
      if (stakedFraction === 0n) {
        setPendingRewards("0");
        return;
      }

      const userReward = (amount * blocksPassed * 1000000000000000000n) / TOTAL_MCSASHA;
      setPendingRewards(formatEther(userReward.toString()));
    } catch (error) {
      console.error("Error calculating pending rewards:", error);
    }
  };

  const handleApprove = async () => {
    if (!signer || !stakeAmount) return;
    try {
      const mcSasha = new Contract(MCSASHA_ADDRESS, MCSASHA_ABI, signer);
      const amountWei = parseEther(stakeAmount);
      let tx = await mcSasha.approve(STAKING_ADDRESS, amountWei);
      await tx.wait();
      alert("Approved successfully!");

      // Aktualizacja allowance po zatwierdzeniu
      const updatedAllowance = await mcSasha.allowance(account, STAKING_ADDRESS);
      setAllowance(BigInt(updatedAllowance.toString()));
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleStake = async () => {
    if (!signer || !stakeAmount) return;
    try {
      const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);
      const amountWei = parseEther(stakeAmount);
      let tx = await staking.stake(amountWei);
      await tx.wait();
      alert("Staked successfully!");
      await fetchAllData(account, signer);
    } catch (error) {
      console.error("Error in handleStake:", error);
    }
  };

  const handleApproveAndStake = async () => {
    if (!signer || !stakeAmount) return;
    try {
      const mcSasha = new Contract(MCSASHA_ADDRESS, MCSASHA_ABI, signer);
      const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);

      // Zatwierdzenie maksymalnej kwoty (unlimited)
      let tx = await mcSasha.approve(STAKING_ADDRESS, MAX_UINT256);
      await tx.wait();
      alert("Approved unlimited successfully!");

      // Aktualizacja allowance po zatwierdzeniu
      const updatedAllowance = await mcSasha.allowance(account, STAKING_ADDRESS);
      setAllowance(BigInt(updatedAllowance.toString()));

      // Stakowanie wskazanej kwoty
      const amountWei = parseEther(stakeAmount);
      tx = await staking.stake(amountWei);
      await tx.wait();
      alert("Staked successfully!");

      // Odświeżenie danych
      await fetchAllData(account, signer);
    } catch (error) {
      console.error("Error in handleApproveAndStake:", error);
    }
  };

  const handleUnstake = async () => {
    if (!signer || !unstakeAmount) return;
    try {
      const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);
      const amountWei = parseEther(unstakeAmount);
      let tx = await staking.unstake(amountWei);
      await tx.wait();
      alert("Unstaked successfully!");
      await fetchAllData(account, signer);
    } catch (error) {
      console.error("Error in handleUnstake:", error);
    }
  };

  const handleClaim = async () => {
    if (!signer) return;
    try {
      const staking = new Contract(STAKING_ADDRESS, STAKING_ABI, signer);
      let tx = await staking.claimRewards();
      await tx.wait();
      alert("Rewards claimed!");
      await fetchAllData(account, signer);
    } catch (error) {
      console.error("Error in handleClaim:", error);
    }
  };

  // Funkcja do obsługi muzyki
  const toggleMusic = () => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  // Funkcja do ustawienia maksymalnej kwoty stakowania
  const handleMaxStake = () => {
    setStakeAmount(balance);
  };

  // Funkcja do skracania adresu
  const shortenAddress = (addr) => {
    if (!addr) return "";
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  return (
    <div className="container">
      <header className="header">
        <div className="logo-container">
          <img src="/logo512.png" alt="McSasha Logo" className="logo" />
          <h1>McSasha Staking</h1>
        </div>
        <div className="header-controls">
          {!account && (
            <button className="btn primary" onClick={connectWallet}>Connect Wallet</button>
          )}
          {account && <p className="account">Connected: {shortenAddress(account)}</p>}
          {account && (
            <button className="btn music-btn" onClick={toggleMusic}>
              {isPlaying ? "🔊 Music ON" : "🔇 Music OFF"}
            </button>
          )}
        </div>
      </header>
      
      <audio ref={audioRef} src="/music.mp3" loop />

      {account && (
        <main className="main-content">
          <div className="info-box">
            <p>Your MCSASHA Balance: {balance}</p>
            <p>Your Staked MCSASHA: {staked}</p>
            <p>Your Pending MCBURGERS: {pendingRewards}</p>
          </div>

          <div className="action-box">
            <h2>Stake MCSASHA</h2>
            <div className="stake-input-container">
              <input
                type="text"
                placeholder="Amount to stake"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                className="input-field"
              />
              <button className="btn max-btn" onClick={handleMaxStake}>MAX</button>
            </div>
            <div className="action-btns">
              {allowance >= BigInt(parseEther(balance).toString()) ? (
                // Jeśli zatwierdzono unlimited, pokaż tylko przycisk Stake
                <button className="btn primary" onClick={handleStake}>Stake</button>
              ) : (
                // W przeciwnym razie, pokaż przycisk Approve & Stake
                <button className="btn primary" onClick={handleApproveAndStake}>Approve & Stake</button>
              )}
            </div>
          </div>

          <div className="action-box">
            <h2>Unstake MCSASHA</h2>
            <input
              type="text"
              placeholder="Amount to unstake"
              value={unstakeAmount}
              onChange={(e) => setUnstakeAmount(e.target.value)}
              className="input-field"
            />
            <button className="btn primary" onClick={handleUnstake}>Unstake</button>
          </div>

          <div className="action-box">
            <h2>Claim Rewards</h2>
            <button className="btn primary" onClick={handleClaim}>Claim MCBURGERS</button>
          </div>

          <div className="trading-links">
            <p>McSasha is available for trading at: 
              <a 
                href="https://units.swop.fi/#/swap?outputCurrency=0x6DFE63380149E04f4DD9BD7E8d892eEc28878556" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn-link"
              >
                Swap McSasha
              </a>
            </p>

            <p>McBurgers is available for trading at: 
              <a 
                href="https://units.swop.fi/#/swap?outputCurrency=0x487920d6462bb8EdBe0e0E67EeAB04dC9174303" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn-link"
              >
                Swap McBurgers
              </a>
            </p>
          </div>
        </main>
      )}
    </div>
  );
}

export default App;
